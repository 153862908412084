import React from "react"
import { keyframes, css } from "@emotion/core"
import styled from "@emotion/styled"
import { Backpack as BackpackComponent } from "react-kawaii"

const upDownAnimation = keyframes`
  from, to, 30% {
    transform: translateY(0px)
  }

  20% {
    transform: translateY(-15px)
  }
`

const BackpackAnimated = styled(BackpackComponent)`
  animation: ${({ mood }) =>
    "ko" !== mood &&
    css`
      ${upDownAnimation} 6.5s ease-in-out infinite
    `};
`

export default function Backpack({ mood, setMood }) {
  React.useEffect(() => {
    let mounted = true

    setTimeout(() => {
      if (mounted) {
        switch (mood) {
          case "excited":
            setMood("blissful")
            break

          case "blissful":
            setMood("excited")
            break

          default:
            break
        }
      }
    }, 4500)

    return () => {
      mounted = false
    }
  }, [mood, setMood])

  return (
    <div aria-hidden="true">
      <BackpackAnimated size={354} mood={mood} color="#3DA9FC" />
    </div>
  )
}
